import styled from 'styled-components';

export const StyledTextInput = styled.input`
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border: none;
  box-shadow: 0 4px 32px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.8rem;
  
  &:focus {
    outline: none;
  }
  
  &::placeholder {
    color: #000;
  }
`;

export const StyledTextArea = styled.textarea`
  font: inherit;
  font-size: 14px;
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border: none;
  box-shadow: 0 4px 32px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.8rem;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #000;
  }
`;

export const StyledSelect = styled.select`
  font: inherit;
  font-size: 14px;
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border: none;
  box-shadow: 0 4px 32px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.8rem;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #000;
  }
  
  -webkit-appearance: none;
  -moz-appearance: none;
`;