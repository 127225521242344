import React, { useState } from 'react';
import { useFormspark } from '@formspark/use-formspark';
import { PrimaryButton } from '../StyledButtons/styled';
import { StyledSelect, StyledTextArea, StyledTextInput } from './styled';

export default function Form({ setSendStatus }) {
    const [contact, setContact] = useState({ name: '', email: '', reason: '', message: '' });
    const [submit, submitting] = useFormspark({
        formId: 'BKsiJzwS'
    });

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setContact({ ...contact,[name]: value });
    };

    return (
        <form onSubmit={async (e) => {
            e.preventDefault();
            await submit({ contact }).then(() => {
                setSendStatus(true);
            });
        }} className='flex flex-column w-20rem md:w-26rem font-roboto'>
            <StyledTextInput value={contact.name} name='name' onChange={handleChange} placeholder='Your name*' type='text' />
            <StyledTextInput value={contact.email} name='email' onChange={handleChange} placeholder='Your email*' type='text' />
            <StyledSelect value={contact.reason} onChange={handleChange} name='reason' id="reason">
                <option value="other">Why are you contacting us?*</option>
                <option value="support">Support</option>
                <option value="business">Business</option>
                <option value="press">Press</option>
                <option value="other">Other</option>
            </StyledSelect>
            <StyledTextArea value={contact.message} name='message' onChange={handleChange} className='mb-8' placeholder='Message' rows='3' type='text' />
            <div className='flex justify-content-center font-helvetica'>
                <PrimaryButton type='submit' disable={submitting}>Send</PrimaryButton>
            </div>
        </form>
    );
}