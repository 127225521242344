import React, { useState } from 'react';

// styles
import '../styles/index.css';
import '/node_modules/primeflex/primeflex.css';

// components
import MainLayout from '../hoc/MainLayout';
import Form from '../components/shared/Form';
import CheckIcon from '../assets/check-icon.svg';
import { PrimaryLinkButton } from '../components/shared/StyledButtons/styled';
import { loadCrispChat } from '../common/utils';
import Seo from '../components/shared/Seo';

const ContactPage = () => {
    loadCrispChat();
    const [sendStatus, setSendStatus] = useState(false);

    return (
        <MainLayout>
            <Seo title='Lofi.co - Craft your focus environment' keywords={['music', 'focus', 'relax', 'work']} lang='en' />
            <main>
                <div className='flex flex-column align-items-center justify-content-center mb-8' style={{ height: '70vh' }}>
                    {sendStatus ?
                        <div className='font-helvetica flex flex-column align-items-center'>
                            <CheckIcon className='mb-6' />
                            <h2 className='text-center text-3xl md:text-4xl font-bold mb-6'>
                                Your message has been sent.
                            </h2>
                            <p className='text-center mb-6'>
                                <span className='block'>Please check your spam inbox if you don’t receive a</span>
                                <span className='block'>reply within 7 days.</span>
                            </p>
                            <PrimaryLinkButton to='/'>Home</PrimaryLinkButton>
                        </div>
                        :
                        <>
                            <h2 className='text-center text-4xl md:text-5xl font-bold mb-6'>
                                Contact us
                            </h2>
                            <Form setSendStatus={setSendStatus} />
                        </>
                    }

                </div>
            </main>
        </MainLayout>
    );
};

export default ContactPage;